<template>
  <HomeBanner />
  <About />
  <Technologies id="technologies"/>
  <Projects />
  <WorkPolicy id="policy"/>
  <Pricing id="pricing"/>
  <References />
</template>

<script>
import HomeBanner from "@/components/HomeBanner.vue";
import About from "@/components/About.vue";
import Technologies from "@/components/Technologies.vue";
import Projects from "@/components/Projects.vue";
import WorkPolicy from "@/components/WorkPolicy.vue";
import Pricing from "@/components/Pricing.vue";
import References from '@/components/References.vue';

export default {
  components: {
    HomeBanner,
    About,
    Technologies,
    Projects,
    WorkPolicy,
    Pricing,
    References,
  },
};
</script>
